@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "LaFabrique";
  src: local("LaFabrique"), url(./assets/LaFabrique.woff) format("woff");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@import url("https://fonts.googleapis.com/css2?family=Gilda+Display&display=swap");

.react-chatbot-kit-user-avatar-icon{
  display: none !important;
}

.react-chatbot-kit-chat-bot-avatar{
  display: none !important;
}

.react-chatbot-kit-chat-inner-container{
  text-align: left !important;
  max-width: 50vw !important;
}

.react-chatbot-kit-chat-container{
  /* height: 60vh;
  position: absolute;
  bottom: 0; */
  position: relative;
  min-height: 60vh;
  /* padding-bottom: 30px */
}

.react-chatbot-kit-chat-input-container{
  position: absolute;
  bottom: 0;
  left: 0;
}

.react-chatbot-kit-user-chat-message{
  color: #a20000 !important;
  text-shadow: none !important;
}

.container {
  /* height:100vh;
    width:100vw;
    position: absolute; */
  /* background: #000000e6; */
  background-image: url("./assets/background_image.png");
  /* background-size: cover; */
  /* background: black; */
  overflow: hidden;
  /* cursor: url("./assets/egg.cur") 32 32, pointer; */
  /* position: fixed; */
  min-height: 100vh;
  min-width: 100vw;
  z-index: 10000000;
}

.containerhome {
  /* height:100vh;
    width:100vw;
    position: absolute; */
  background: #000000;
  background-image: url("./assets/background_image.png");
  /* background-size: cover; */
  /* background: black; */
  overflow: hidden;
  /* cursor: url("./assets/egg.cur") 32 32, pointer; */
  /* position: fixed; */
  min-height: 100vh;
  min-width: 100vw;
  z-index: 10000000;
}


.containermorning {
  /* height:100vh;
    width:100vw;
    position: absolute; */
  /* background: #000000e6; */
  background-image: url("./assets/background_image.png");
  /* background-size: cover; */
  /* background: black; */
  overflow: hidden;
  /* cursor: url("./assets/egg.cur") 32 32, pointer; */
  position: relative;
  z-index: 10000000;
  height: 76vh;
  width: 60vw;
}

.containermorningmb{
  background-image: url("./assets/background_image.png");
  overflow: hidden;
  position: relative;
  z-index: 10000000;
  height: 60vh;
  width: 100vw;
}

#scene{
  height: 70vh !important;
  width: 60vw !important;
  /* filter: blur(0.7px); */
}



canvas{
  height: 75vh !important;
  width: 65vw !important;
}

.tooltip{
  position:relative;
  /* color: red !important; */
  }
  .tooltip:hover{
  text-decoration:none;
  }
  .tooltip:after{
  content: attr(tip);
  font-family:Arial, Helvetica, sans-serif;
  font-size:90%;
  line-height:1.2em;
  font-size: 1rem;
  width:200px;
  padding:5px 10px;
  /* padding-bottom:50px; */
  position:absolute;
  top:37px;
  left:0px;
  display:none;
  }
  .tooltip:before{
  z-index:1000;
  position:absolute;
  content:"";
  top:15px;
  left:0px;
  border-right:7px transparent solid;
  border-left:7px transparent solid;
  display:none;
  }
  .tooltip:hover{
  z-index:1000;
  /* position:relative; */
  }
  .tooltip:hover:after{
  display:block;
  }
  .tooltip:hover:before{
  display:block;
  }

  .tooltipw{
    position:relative;
    color: white !important;
    }
    .tooltipw:hover{
    text-decoration:none;
    }
    .tooltipw:after{
    content: attr(tip);
    font-family:Arial, Helvetica, sans-serif;
    font-size:90%;
    line-height:1.2em;
    font-size: 1rem;
    width:200px;
    padding:5px 10px;
    /* padding-bottom:50px; */
    position:absolute;
    top:37px;
    left:0px;
    display:none;
    }
    .tooltipw:before{
    z-index:1000;
    position:absolute;
    content:"";
    top:15px;
    left:0px;
    border-right:7px transparent solid;
    border-left:7px transparent solid;
    display:none;
    }
    .tooltipw:hover{
    z-index:1000;
    /* position:relative; */
    }
    .tooltipw:hover:after{
    display:block;
    }
    .tooltipw:hover:before{
    display:block;
    }

  .bottomegg{
    bottom:-10vh !important;
    position: absolute !important;
    top: 0vh;
    flex-wrap: wrap;
    display: flex !important;
    align-content: flex-end;
    height:100vh !important;
  }

.containerchat {
  /* height:100vh;
    width:100vw;
    position: absolute; */
  /* background: #220000; */
  background: #160000;
  /* background-size: cover; */
  /* background: black; */
  overflow: hidden !important;
  overflow-scroll: hidden !important;
  /* cursor: url("./assets/egg.cur") 32 32, pointer; */
  position: relative;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 10000000;
}

.inner {
  border:  2px solid red;
  border-radius: 30px;
  /* min-height: 100vh; */
  height: 95vh;
  /* min-width: 100vw; */
  z-index: 10000000;
  margin: 1vw;
  min-width: auto;
  overflow: hidden;
}


.innerchatmb{
  position: absolute;
  top: -23vh;
}

.innerchat{
  position: absolute;
  top: -3vh;
}

.innermb {
  /* border:  2px solid red;
  border-radius: 10px; */
  /* min-height: 95vh; */
  /* min-width: 100vw; */
  z-index: 10000000;
  /* position: absolute;
  top: 0;
  bottom:0;
  left:0;
  right:0; */
  margin: auto;
  min-width: auto;
  overflow: hidden;
}

.outtermb{
  /* margin: 1rem; */
  /* border:  1px solid red; */
  border-radius: 10px;
  position: absolute;
  top: 0;
  bottom:0;
  left:0;
  right:0;
}

.activesec{
  border: 1px solid red;
  border-bottom: 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-radius: 5px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 60vh;
  height: 50vh;
  overflow: hidden;
  position: relative;
}

.unactivesec{
  /* border:  2px solid red; */
  /* border-radius: 10px; */
  height: 32vh;
}

.logombchat{
  justify-content: center;
  display: flex;
  padding: 2rem;
}

#containermb {
  display: flex;
  flex-direction: column;;
}

.mainTextchatsmmb{
  font-size: 6vw;
  color: red;
  font-family: 'VT323', monospace;
  text-align: center;
  position: relative;
  bottom: 0;
  display: block;
  border-radius: 10px;
  background: 
  linear-gradient(90deg, red 50%, transparent 0) repeat-x,
  linear-gradient(90deg, red 50%, transparent 0) repeat-x,
  linear-gradient(0deg, red 50%, transparent 0) repeat-y,
  linear-gradient(0deg, red 50%, transparent 0) repeat-y;
  background-size: 4px 1px, 4px 1px, 1px 4px, 1px 4px;
  background-position: 0 0, 0 100%, 0 0, 100% 0;
  box-shadow: 0 0 10px red;
}

.loading{
  text-align: center;
  position: relative;
  background-color: #380a0a;
  font-size: 6vw;
  font-family: 'VT323', monospace;
  /* float:left; */
  width:100%;
  height:100%;
}

.loadingtext{
  font-size: 2vw;
  color: red;
  font-family: 'VT323', monospace;
  text-align: center;
}

.videoegg{
  height: 100vh;
  width: 100vw;
  display: flex;
}

.center{
  position:absolute;
  left:50%;
  top:50%;
}

.centermb{
  position: absolute;
  left: -50%;
  top: 22vh;
  margin: auto;
  width: 200vw;
  height: 100vh;
}



.otext{
  font-size: 15vw;
  color: red;
  font-family: "Gilda Display", serif;
  text-align: center;
}

.closecont{
  /* border-top-left-radius: 30px;
  border-top-right-radius: 30px; */
  border:  1.5px solid red;
  border-top: 0;
  font-size: 1vw;
  color: red;
  text-transform: uppercase;
  box-shadow: 0 0 10px red;
  background-color: #160000;
  z-index: 10000000;
  position: relative;
}

.close{
  text-align: end;
  padding: .2rem;
  z-index: 10000000;
}

.marquee{
  font-size: 1vw;
  color: red;
  /* border-top-left-radius: 30px;
  border-top-right-radius: 30px; */
  border:  1.5px solid red;
  border-bottom:  1.5px solid red;
  border-top: 0;
  font-family: 'VT323', monospace;
  text-transform: uppercase;
  /* box-shadow: 0 0 10px red; */
  text-shadow:0px 0px 5px red,0px 0px 5px #a20000;
}


.marqueebot{
  font-size: 1vw;
  color: red;
  /* border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border:  1.5px solid red; */
  width: 100vw;
  /* border-top: 0; */
  font-family: 'VT323', monospace;
  text-transform: uppercase;
  text-shadow:0px 0px 5px red,0px 0px 5px #a20000;
  /* box-shadow: 0 0 10px red; */
}


.marqueetext{
  padding: .3rem;
  padding-bottom: 0rem;
}

.bottom{
  position: absolute;
  bottom: 0;
}

.left{
  float:left;
  z-index: 10000000;
  /* position: relative;
  top: 0;
  left: 0;
  right: 0; */
  height: 100vh;
  width: 30vw;
  bottom: 0;
  margin: auto;
  display:flex;
  flex-direction: column;
  /* padding: 3rem; */
}

.boxWrapper{
  display: inline-block;
  width: 100%;
}

.right{
  float: right;
  z-index: 10000000;
  /* position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
  height: 70vh;
  width: 65vw;
  /* border: 3px solid red; */
  /* margin: auto; */
  margin: 15vh auto;
  /* position: absolute; */
  /* left: 55vw; */
  overflow: hidden;
  z-index: 1000;
}


.overlayimg {
  position: absolute;
  opacity: .08;
  z-index: 10000000000;
  pointer-events: none;
  background-image: repeating-linear-gradient(233deg, #160000, #6d010170 1px, transparent -109px, transparent 2px);
}




.scene{
  /* float: left; */
  z-index: 10000000;
  /* position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
  height: 70vh;
  width: 60vw;
  border: 3px solid red;
  box-shadow: 0 0 10px red;
  position: relative;
  left: 5%;
  overflow: hidden;
  /* margin: auto; */
  /* margin: 15vh auto; */
  /* position: absolute; */
  /* left: 55vw; */
}

.scenemb{
  /* float: left; */
  z-index: 10000000;
  /* position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
  height: 100vh;
  width: 100vw;
  border: 3px solid red;
  box-shadow: 0 0 10px red;
  position: relative;
  /* left: 5%; */
  /* margin: auto; */
  /* margin: 15vh auto; */
  /* position: absolute; */
  /* left: 55vw; */
}

.rightpd{
  padding: 3rem;
}

.mainTextchat{
  /* font-size: 19vw; */
  color: red;
  font-family: "Gilda Display", serif;
  text-align: center;
  position: relative;
  top: 0;
  left: 2vw;
  right: 0;
  bottom: 0;
  /* width: 65vw; */
  padding: 3vh;
  /* padding-bottom: 10vh; */
  padding-bottom: 0vh;
}

.big{
  font-size: 19vw; 
}

.logochatsm{
  margin: auto;
    margin-top: 0;
    margin-bottom: 0;
    padding: 1.5vh;
    position: relative;
    top: 0;
    left: 3vw;
}

.mainTextchatsm{
  font-size: 2vw;
  color: red;
  font-family: 'VT323', monospace;
  text-align: center;
  position: relative;
  top: 0;
  left: 3vw;
  right: 0;
  width: 27vw;
  bottom: 0;
  display: block;
  /* border:  1px dashed red; */
  border-radius: 10px;
}

.mainTextchatsm{
  background: 
  linear-gradient(90deg, red 50%, transparent 0) repeat-x,
  linear-gradient(90deg, red 50%, transparent 0) repeat-x,
  linear-gradient(0deg, red 50%, transparent 0) repeat-y,
  linear-gradient(0deg, red 50%, transparent 0) repeat-y;
background-size: 4px 1px, 4px 1px, 1px 4px, 1px 4px;
background-position: 0 0, 0 100%, 0 0, 100% 0;
box-shadow: 0 0 10px red;
}


.mainTextchatsm:hover {
  animation: linearGradientMove .3s infinite linear;
  background-color: #730101;
  transition: all .3s;
}

.mainTextchatsm.active{
  animation: linearGradientMove .3s infinite linear;
  background-color: #730101;
  transition: all .3s;
}

.mainTextchatsmmb.active{
  /* animation: linearGradientMove .3s infinite linear; */
  animation: 1s infinite blink;
  background-color: #730101;
  transition-duration: 20s;
  animation-timing-function: ease-in-out;
}

/* .vidmb{
  width: 200vw;
    left: -55vw;
    top: 21vh;
    position: relative;
} */

.blinkp {
  animation: 1s infinite blinkp;
  display: inline-block;
  z-index: 1000000;
}

.blinkpstill {
  /* animation: 1s infinite blinkp; */
  display: inline-block;
  z-index: 1000000;
}

@keyframes blinkp {
  0%, 15% {
      opacity: 0;
  }
  16%, 100% {
      opacity: 1;
  }
}

@keyframes linearGradientMove {
  100% {
      background-position: 4px 0, -4px 100%, 0 -4px, 100% 4px;
  }
}
@keyframes blink {
  0%, 15% {
    /* opacity: 0; */
    background-color: #160000;
}
  16%, 100% {
    /* opacity: 1; */
    background-color: #730101;
}
}

.chatbtn{
padding: 1.5vh;
}

.mobilevid{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.pattern10 {
  background-image: repeating-linear-gradient(233deg, #160000, #6d010170 1px, transparent -109px, transparent 2px);
  background-size: 10px;
}

.logobg{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 500px;
  height: 500px;
  margin-top: -250px; /* Half the height */
  margin-left: -250px; /* Half the width */
  z-index: 100000;
}
/* .containersc{
  height: 10vh;
 width: 10vw;
} */

.containerHome{
  min-height: 100vh;
  min-width: 100vw;
}

.containernope {
  height: 100vh;
  width: 100vw;
  position: absolute;
  /* background: #000000e6; */
  /* background: white;
    color:black; */
  background: black;
  overflow: hidden;
  /* cursor: url("./assets/egg.cur") 32 32, pointer; */
}

.container a {
  /* cursor: url("./assets/egg.cur") 32 32, pointer; */
}

.mainText {
  font-size: 19vw;
  color: white;
  font-family: "Gilda Display", serif;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
  z-index: 10;
  text-align: center;
}

.mainTextsm{
  font-size: 10vw;
  color: white;
  font-family: "Gilda Display", serif;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
  z-index: 10;
  text-align: center;
}

.goo{
  z-index: 1;
}

.yesNo{
  font-size: 19vw;
  color: white;
  font-family: "Gilda Display", serif;
  /* position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto; */
  text-align: center;
  width: fit-content;
  height: fit-content;
}

.egghome {
  position: absolute;
  top: -20vh;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 100;
  text-align: center;
  /* animation: cssAnimation 0s 2s forwards; */
       animation: horizontal-shaking 0.15s infinite;
  /* visibility: hidden; */
}

.prompt{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 20000;
  text-align: center;
}

.fade{
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
   -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
       animation: fadein 2s;
}

@keyframes cssAnimation {
  to   { visibility: visible; }
}

/* .soundcont{
  color: white;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
} */


.moving-text:hover{
  animation-play-state: paused;
}

.moving-textbot:hover{
  animation-play-state: paused;
}
/* The animation */
@keyframes marquee{
  0%{transform: translateX(100%);}
  100%{transform: translateX(-100%);}
}

/* media query to enable animation for only those who want it */
@media (prefers-reduced-motion: no-preference) {
  .moving-text{
      animation: marquee 50s linear infinite;
  }
  .moving-textbot{
    animation: marquee 80s linear infinite;
}
}


.moving-textmani:hover{
  animation-play-state: paused;
}

/* The animation */
@keyframes marqueemai{
  0%{transform: translateY(30%);}
  100%{transform: translateY(-100%);}
}

/* media query to enable animation for only those who want it */
@media (prefers-reduced-motion: no-preference) {
  .moving-textmani{
      animation: marqueemai 45s linear infinite;
  }
}

/* Large devices such as laptops (1024px and up) */
@media only screen and (min-width: 64em) {
  @keyframes marqueemai{
    0%{transform: translateY(60%);}
    100%{transform: translateY(-100%);}
  }
 /* media query to enable animation for only those who want it */
@media (prefers-reduced-motion: no-preference) {
  .moving-textmani{
      animation: marqueemai 25s linear infinite;
  }
}
}

/* Largest devices such as desktops (1280px and up) */
@media only screen and (min-width: 80em) {
  @keyframes marqueemai{
    0%{transform: translateY(60%);}
    100%{transform: translateY(-100%);}
  }
/* media query to enable animation for only those who want it */
@media (prefers-reduced-motion: no-preference) {
  .moving-textmani{
      animation: marqueemai 25s linear infinite;
  }
}
}

.flex{
  display: flex;
  flex-direction: column;
}

.mani{
  width: 30vw;
  font-size: 1rem;
  z-index: 100;
}

.manimb{
  width: 80vw;
  font-size: 1rem;
  z-index: 100;
}

#content-wrap {
  padding-bottom: 2.5rem; /* Footer height */
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
  font-size: 1vw;
  color: white;
  transition: opacity var(--speedx6) var(--ease); /* Footer height */
}

#footerScene {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4.5rem;
  font-size: 1vw;
  display: flex;
  padding: 2rem;
  padding-bottom: 1rem;
  padding-top: 0;
  justify-content: space-between;
  color: white;
  /* mix-blend-mode: diffrence; */
  transition: opacity var(--speedx6) var(--ease); /* Footer height */
}

.sound {
  display: flex;
}

.morninglogo {
  font-size: 2vw;
}

.sound img {
  padding-bottom: 2rem;
  /* padding-: 2rem; */
}

#footermobile {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
  font-size: 1vw;
  color: white;
  z-index: 1000;
}

/* .mainTextmobile {
  font-size: 15vw;
  color: white;
  font-family: "Gilda Display", serif;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
  z-index: 10;
  text-align: center;
} */

.nopetext {
  font-size: 20vw;
  /* color: black; */
}

.flame {
  font-size: 5vw;
  position: relative;
  font-weight: lighter;
  font-family: "Gilda Display", serif;
}

.miniText {
  font-size: 0.8vw;
  text-transform: none;
  width: auto;
  /* margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
  padding: 0.75rem;
  z-index: 100;
  /* text-shadow: 0 0 5px #fff,
                0 0 10px #fff,
                0 0 20px white,
                0 0 30px white,
                0 0 40px black,
                0 0 55px black,
                0 0 70px black; */
}

.miniminiText {
  font-size: 3vw;
  text-transform: uppercase;
  /* margin: auto; */
  padding: 0.75vw;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  z-index: 100;
  /* border-radius: 100%;
    border: 1px solid; */
  /* border: solid; */
  /* border-radius: 10px 10px 0 0;
    box-shadow: 0 0 20px rgba(0,0,0,.6); */
}

.miniminiText li {
  padding: 0.75rem;
  max-width: 90%;
}

.miniminiTextmobile {
  font-size: 4vw;
  text-transform: uppercase;
  padding: 0.75vw;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: nowrap;
  z-index: 100;
}

.svg{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.miniTextmobile {
  font-size: 4vw;
  text-transform: none;
  max-width: 100%;
  margin: auto;
  /* position: absolute; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem;
  z-index: 100;
}

.blur {
  filter: blur(16px);
}

#noBlur {
  filter: blur(0px);
}

#Blur {
  filter: blur(16px);
}

.blur2 {
  filter: blur(3px);
}

.blur3 {
  filter: blur(10px);
}

.shake:hover {
  animation: horizontal-shaking 0.35s infinite;
  z-index: 100;
}

.shake {
  z-index: 100;
  text-transform: none;
    /* position:relative; */
}

.shake1:hover {
  animation: horizontal-shaking 0.55s infinite;
  z-index: 100;
}

.shake1 {
  z-index: 100;
  text-transform: none;
    /* position:relative; */
}

.shakemobile{
  z-index: 100;
  text-transform: none;
  animation: horizontal-shaking 0.15s infinite;
}

.shadowfilter {
  -webkit-filter: drop-shadow(2px 2px 12px rgba(255, 255, 255, .2));
  filter: drop-shadow(2px 2px 12px rgba(255, 255, 255, .2));
}

.shadowfilter:hover {
  -webkit-filter: drop-shadow(2px 2px 12px rgba(255, 255, 255, .2));
  filter: drop-shadow(2px 2px 12px rgba(255, 255, 255, .2));
  animation-name:glowegg;
  animation-duration:1s;
  animation-iteration-count:infinite;
  animation-direction:alternate;
}


@keyframes glowegg {
  from {
      filter: drop-shadow(0px 0px 0px rgba(255, 255, 255, .3));
  }
  to {
      filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, .2));
  }
}


.marquee{
  font-size: 2vh;
}

/* butt */

.header {
  z-index: 20000;
  /* padding: 1rem; */
  position: absolute;
  /* top: 1vh; */
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
}

.button--toggle,
.button--close {
  color: #e6e6e6;
  border: 2px solid #0000;
  border-radius: 50%;
  padding: 0.25rem;
  transition: transform 0.2s, border-color 0.4s;
  transform: scale(0.95);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
}

.button--toggle svg,
.button--close svg {
  display: block;
}

.shakebut{
  padding: 2rem;
}

.shakebutcon{
  padding: .5rem;
  border: .5px solid white;
  font-size: 1.5vw;
  color: white;
  text-align: center;
  position: relative;
  bottom: 0;
  display: block;
  border-radius: 50px;
  width: 100px;
  /* box-shadow: 0 0 10px white; */
}


.shakebutconmb{
  padding: .5rem;
  border: .5px solid white;
  font-size: 5vw;
  color: white;
  text-align: center;
  position: relative;
  bottom: 0;
  display: block;
  border-radius: 50px;
  /* box-shadow: 0 0 10px white; */
}

.yes:hover{
  background-color: green;
  box-shadow: 0 0 10px green;
  border: .5px solid green;
}

.no:hover{
  background-color: red;
  box-shadow: 0 0 10px  red;
  border: .5px solid red;
}

.yesmb{
  box-shadow: 0 0 10px green;
  border: .5px solid green;
}

.nomb{
  box-shadow: 0 0 10px  red;
  border: .5px solid red;
}


.space{
  padding: 2rem;
}

.spacemb{
  padding: 1rem;
}
/* vid */
.background__video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* opacity: 0;  */
  /* visibility: hidden; */
}

.flame__video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* vod2 */

#loader {
  /* position: fixed; */
  width: 100%;
  height: 100%;
  /* background: url("./assets/flame.gif") no-repeat center; */
  background-size: cover;
  z-index: 999;
}

.static {
  position: absolute;
  background: white;
}

.static:hover {
  opacity: 0;
}

/* popup */
.card {
  position: absolute;
  height: fit-content;
  height: -moz-fit-content;
  padding: 9px 12px 9px 13px;
  margin: 0 var(--gap) var(--gap) 0;
  float: left;
  border-radius: 10px;
  background-color: rgba(150, 150, 150, 0.2);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: transform var(--speed) var(--ease);
  transition: transform var(--speed) var(--ease);
  overflow: hidden;
  cursor: grab;
  z-index: 100000 !important;
  font-size: 0.8rem;
  text-transform: none;
  max-width: 80%;
  margin: auto;
  top: -10vh;
  left: 0;
  right: 0;
  bottom: 0;
}

.cardmobile {
  /* position: absolute; */
  height: fit-content;
  height: -moz-fit-content;
  padding: 9px 12px 9px 13px;
  margin: 0 var(--gap) var(--gap) 0;
  float: left;
  border-radius: 10px;
  background-color: rgba(150, 150, 150, 0.2);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: transform var(--speed) var(--ease);
  transition: transform var(--speed) var(--ease);
  overflow: hidden;
  cursor: grab;
  z-index: 100000 !important;
  font-size: 0.8rem;
  text-transform: none;
  max-width: 100%;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.glow{
  animation-name:glow;
  animation-duration:1s;
  animation-iteration-count:infinite;
  animation-direction:alternate;
}

@keyframes glow{
  from{text-shadow:0px 0px 5px #b6d99c,0px 0px 5px #FFFFFF;}
  to{text-shadow:0px 0px 10px #FFFFFF,0px 0px 10px #FFFFFF;}
}

footer {
  width: 100%;
  grid-row: 3;
  align-self: flex-end;
  padding: var(--m) var(--sides-text);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: var(--gap);
  z-index: 30000;
}

svg defs {
  clip: rect(0px, 0px, 0px, 0px);
}
.goo {
  color: white;
  font-size: 10vw;
  text-align: center;
  font-family: sans-serif;
  lini-height: 1;
  letter-spacing: 0.1em;
  transform-origin: left 0.5em;
  -webkit-filter:url("#shadowed-goo");
  filter:url("#shadowed-goo");
  animation: 8s sta ease-in both;
  animation-iteration-count: 1;
  top: 10vh;
  position: relative;
  /* animation-iteration-count: infinite; */
}
@keyframes sta {
	0% {
    transform: scaleY(1) translateY(0);
    opacity: 1;
	}
	10%,20% {
    transform: scaleY(1) translateY(0);
    opacity: 1;
	}
	100% {
    transform: scaleY(8) translateY(0.5em);
    opacity: 0;
	}
}


.chat {
  text-align: center;
  /* background-color: black; */
  /* font-size: 2vw; */
  min-height: 65vh;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  font-family: 'VT323', monospace;
  color: red;
  text-shadow:0px 0px 5px red,0px 0px 5px #a20000;
}

.chatmb{
  text-align: center;
  /* background-color: black; */
  /* font-size: 2vw; */
  min-height: auto;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  font-family: 'VT323', monospace;
  color: red;
  text-shadow: 0px 0px 5px red, 0px 0px 5px #a20000;
}

.chatnogl{
  text-align: center;
  /* background-color: black; */
  /* font-size: 2vw; */
  min-height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'VT323', monospace;
  color: red;
  /* text-shadow:0px 0px 5px red,0px 0px 5px #a20000; */
}

.bold{
  text-shadow:0px 0px 5px red,0px 0px 5px #a20000;
}

.react-chatbot-kit-chat-input{
  background-color: #ff000000 !important;
    margin: 0.5rem;
    width: 20rem;
    /* width: 20vw; */
    font-family: 'VT323', monospace;
    color: red !important;
    border:  2px solid red;
    border-radius: 30px;
    /* animation: 1s infinite blink !important; */
}

.chatmb{
  /* width: 50vw !important; */
}

.react-chatbot-kit-chat-input:focus {
  outline: none !important;
  border:2px solid #220000;
  background-color: #220000 !important;
  box-shadow: 0 0 10px red;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: red !important;
  padding: 0.3rem;
  /* text-shadow:0px 0px 5px red,0px 0px 5px #a20000; */
  /* opacity: 1; Firefox */
  /* display: inline-block; */
}


/* interact */
/* #canvas {
    background: #000;
    filter: blur(60px);
    height: 100vh;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 10000;
} */
/* fade in  */
#test {
  text-align: center;

  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 2s; /* Firefox < 16 */
      -ms-animation: fadein 2s; /* Internet Explorer */
       -o-animation: fadein 2s; /* Opera < 12.1 */
          animation: fadein 2s;
}


#showMe {
  animation: cssAnimation 0s 8s forwards;
  visibility: hidden;
}

#showMecrack {
  animation: cssAnimation 0s 6s forwards;
  visibility: hidden;
  z-index: 1000;
}

#showMecrackm {
  animation: cssAnimationm 0s 6s forwards;
  visibility: hidden;
  z-index: 10000000;
}

@keyframes cssAnimationm {
  to   { visibility: visible; opacity: 1; }
}

#showMecrackw {
  animation: hide 0s 6s forwards;
  visibility: visible;
  z-index: 1000;
}

.vid{
  filter: brightness(60%);
}

@keyframes cssAnimation {
  to   { visibility: visible; }
}

@keyframes hide {
  to   { visibility: hidden; }
}


@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}


/* .blursc {
  border-radius: 20%;
  display: inline-block;
  position: relative;  
  filter: blur(2px);
}
.blursc img {
  border-radius: 50%;
  display: block;
  border:1px solid #fff;
}
.blursc:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: radial-gradient(ellipse at center, rgba(255,255,255,.2) 0%,rgba(255,255,255,.1) 70%,rgba(255,255,255,.1) 100%);
  border-radius: 50%;
  position: absolute;
  top: 0; left: 0;
} */

/* overlay */
.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

canvas{
  height: 100vh !important;
  width: 100vw !important;
}

/* Large devices such as laptops (1024px and up) */
@media only screen and (min-width: 64em) {
  canvas {
    height: 70vh !important;
    width: 65vw !important;
  }
}

/* Largest devices such as desktops (1280px and up) */
@media only screen and (min-width: 80em) {
  canvas {
    height: 70vh !important;
    width: 65vw !important;
  }
}

/* canvas{
  height: 100vh;
  width: 100vw;
} */

/* Large devices such as laptops (1024px and up) */
/* @media only screen and (min-width: 64em) {
  canvas {
    height: 70vh;
    width: 65vw;
  }
} */

/* Largest devices such as desktops (1280px and up) */
/* @media only screen and (min-width: 80em) {
  canvas {
    height: 70vh ;
    width: 65vw ;
  }
} */

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

MenuItem {
  width: 40vw !important;
}

@-webkit-keyframes Gradient {
  0% {
    background-size: 50%;
  }
  50% {
    background-size: 100%;
  }
  100% {
    background-size: 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-size: 50%;
  }
  50% {
    background-size: 100%;
  }
  100% {
    background-size: 50%;
  }
}

@keyframes Gradient {
  0% {
    background-size: 50%;
  }
  50% {
    background-size: 100%;
  }
  100% {
    background-size: 50%;
  }
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
